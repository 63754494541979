import locales from './locales.json';

function getNewRouteLang(pathname) {
    if (!pathname) {
        return null;
    }

    let parts = pathname.split('/').filter((i) => !!i);

    if (!parts.length) {
        return null;
    }

    if (locales.indexOf(parts[0]) !== -1) {
        return parts[0];
    }

    return null;
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    const routeLang = getNewRouteLang(location.pathname);
    if (routeLang) {
        window.document.cookie = `pr_lng=${routeLang}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    }

    // if (prevLocation) {
    //     setTimeout(() => {
    //         let data = window['dataLayer'];
    //         if (data) {
    //             data.push({ event: `gatsby-route-change` });
    //         }
    //     }, 50);
    // }
};
