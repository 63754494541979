module.exports = [{
      plugin: require('../../../node_modules/@solublestudio/soluto-design-system/gatsby-browser.js'),
      options: {"plugins":[],"variablesPath":"/working_dir/packages/design-system/src/themes/greenmotril/variables.scss","components":["Accordion","Breadcrumb","Button","Card","Col","Collapse","Container","GridBox","GridContainer","GridWrapper","HTMLComponent","Header","Heading","HubspotForm","Icon","Image","Input","Layout","LegacyHeader","Link","Modal","Pagination","Row","Section","Slider","SocialIcons","Table","Text","Video","VideoInline"],"alias":{"~icons":"/working_dir/packages/design-system/icons.js","Picture":"/working_dir/node_modules/@solublestudio/soluto-design-system/src/components/Gatsby/Picture","~logo":"/working_dir/packages/design-system/src/img/logo-gm.svg?url","~logo-white":"/working_dir/packages/design-system/src/img/logo-gm.svg?url","@ds/components":"/working_dir/packages/design-system/src/components","@ds/hooks":"/working_dir/packages/design-system/src/hooks","@ds/utils":"/working_dir/packages/design-system/src/utils","@ds/scss":"/working_dir/packages/design-system/src/scss","@ds/types":"/working_dir/packages/design-system/src/types","@ds/img":"/working_dir/packages/design-system/src/img"},"dirname":"/working_dir/packages/design-system","sal":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280,"quality":90,"withWebp":true,"srcSetBreakpoints":[576],"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/@solublestudio/gatsby-theme-soluble-source/gatsby-browser.js'),
      options: {"plugins":[],"local":false,"primaryLanguage":"es","language":{"es":"es","en":"en"},"sitemap":true,"datocms":{"previewMode":false,"modelPages":["Page"],"redirects":false,"localesToGenerate":["es","en"]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Greenmotril","short_name":"Greenmotril","start_url":"/","background_color":"#130e1e","theme_color":"#130e1e","display":"minimal-ui","icon":"src/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5f11b4f6178898b12cec4f57e1728713"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-always-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
